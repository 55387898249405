import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export default function FilterDrawer ({
  children,
  close,
  setClose,
  filters,
  setCloseFilterMenu
}) {
  const [open, setOpen] = React.useState(false)
  const [activeFilters, setActiveFilters] = React.useState(0)

  const toggleDrawer = newOpen => () => {
    setOpen(newOpen)
    setClose(!close)
  }

  React.useEffect(() => {
    if (close === true) {
      setActiveFilters(Object.keys(filters).length)
      setOpen(false)
    }
  }, [close])

  return (
    <div>
      <>
        <Button onClick={toggleDrawer('right', true)}>
          <FilterAltIcon />
          Filters{activeFilters > 0 ? ` (${activeFilters}) active` : ''}
        </Button>
        <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>
          <Box sx={{ width: '750px', backgroundColor: 'white' }}>
            <Box sx={{ padding: '0 1rem' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid lightgray'
                }}
              >
                <h2>Filters:</h2>
                <IconButton onClick={() => setCloseFilterMenu(true)}>
                  <CloseIcon />
                </IconButton>
              </div>
              {children}
            </Box>
          </Box>
        </Drawer>
      </>
    </div>
  )
}
