import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {
  cancelContainer,
  repeatContainer,
  divider,
  headerContainer,
  mainContainer,
  mapSelectContainer,
  mappingContainer,
  mappingText,
  textStyles,
  repeatSectionTitle
} from './styles'
import CustomSelect from '../../../../components/CustomSelect/CustomSelect'
import { useSelector } from 'react-redux'
import { fileMappingOptions } from '../../../../constants/componentConstants'
import {
  useCreateApolloCsvDataMutation,
  useCreateCsvDataMutation
} from '../../../../RTKQuery/CsvDataService/csvDataApi'
import { useUpdateFileMutation } from '../../../../RTKQuery/FileService/fileApi'
import Loader from '../../../../components/Loader/Loader'


const MappingModal = ({
  open,
  setOpen,
  setUploading,
  setUploadModal,
  setMessage,
  setSeverity,
  setShowAlert
}) => {
  const { file, fileHeaders, fileData, Repeating } = useSelector(
    state => state.file
  )

  const [mappedValues, setMappedValues] = useState({})
  const [error, setError] = useState('')

  // Merge Table states
  const [uniqueData, setUniqueData] = useState(fileData && fileData)
  const [
    createCsv,
    { isLoading: csvLoader, isSuccess: csvSuccess, isError: csvError }
  ] = useCreateCsvDataMutation()

  const [updateFile] = useUpdateFileMutation()

  const handleClose = () => {
    setOpen(false)
    setUploading(false)
  }

  const handleGoingBack = () => {
    setUploadModal(true)
    setOpen(false)
  }

  const handleCsvSave = async () => {
    setError('')
    if (mappedValues.emailAddress || mappedValues.email || mappedValues.Email) {
      setUploading(false)
      const newMappedCsv = []
      fileData.forEach(row => {
        const newRow = {}
        Object.entries(row).forEach(([key, val]) => {
          if (mappedValues[key]) {
            newRow[mappedValues[key]] = val
          }
        })
        newMappedCsv.push({ ...newRow, uploadedFile: { id: file.id } })
      })
      if (!Repeating.length > 0) {
        try {
          await updateFile({
            id: file.id
            // formData: { tag: tagId, fileNumber }
          })

          await createCsv(newMappedCsv)
          setMappedValues({})
          setOpen(false)
        } catch (error) {
          console.error(error)
        }
      } else {
        setUniqueData(newMappedCsv)
      }
    } else {
      setError('Please Map headers for the file.')
    }
  }

  // Handle alert messages for stats upload
  useEffect(() => {
    if (csvError) {
      setMessage('Lemlist file upload failed')
      setSeverity('error')
      setShowAlert(true)
    } else if (csvSuccess) {
      setMessage('Users file uploaded successfully!')
      setSeverity('success')
      setShowAlert(true)
    }
  }, [csvError, csvSuccess])

  return (
    <Modal
      open={open}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <>
        <Box sx={mainContainer}>
          <Box sx={headerContainer}>
            <Typography sx={textStyles}>
              <img src='document.png' alt='' />
              {file?.originalName}
            </Typography>
            <IconButton onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Divider sx={divider} />
          <Box sx={mappingContainer}>
            <Typography sx={mappingText}>File Mapping</Typography>
            {fileHeaders.length > 0 ? (
              fileHeaders.map((parameter, index) => (
                <Box sx={mapSelectContainer} key={index + parameter}>
                  <CustomSelect
                    value={parameter}
                    placeholder={'Select a File Tag'}
                    label={`Parameter ${index + 1}`}
                    options={fileHeaders}
                    disabled
                    removeArrow
                  />
                  <CustomSelect
                    value={mappedValues[parameter] || ''}
                    setValue={val =>
                      setMappedValues({ ...mappedValues, [parameter]: val })
                    }
                    placeholder={'Select Field Name'}
                    label={'Map To'}
                    options={fileMappingOptions}
                  />
                </Box>
              ))
            ) : (
              <Loader />
            )}
          </Box>
          <Divider sx={divider} />
          <Box sx={cancelContainer}>
            <Button color='info' variant='contained' onClick={handleGoingBack}>
              Go Back
            </Button>
            <Box>
              <Button
                color='info'
                variant='contained'
                onClick={handleClose}
                sx={{ marginRight: '1rem' }}
              >
                Cancel
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={handleCsvSave}
                // sx={{ backgroundColor: 'white', color: 'black' }}
                disabled={csvLoader}
              >
                {csvLoader ? 'Uploading...' : 'Map and Save'}
              </Button>
            </Box>
          </Box>
          {error && <Alert severity='error'>{error}</Alert>}
        </Box>
      </>
    </Modal>
  )
}

export default MappingModal
