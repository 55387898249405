import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  Box,
  TableFooter,
  TablePagination,
  Typography
} from '@mui/material'
import { useEffect } from 'react'
import {
  headerText,
  noRecord,
  statusText,
  tableContainer,
  tableHeader
} from './styles'
import CachedIcon from '@mui/icons-material/Cached'
import { Header } from '../../../constants/componentConstants'
import { useUpdateEmailVerificationMutation } from '../../../RTKQuery/EmailVerificationService/verificationApi'
import DuplicateDataModal from '../../Modals/DuplicateModal'
import { FormControlLabel, Checkbox, Tooltip } from '@mui/material'
import { IconButton } from '@mui/material'

export default function DetailsTable ({
  label,
  rowsPerPage,
  exportCsv = false,
  data,
  refetchData,
  duplicates,
  handleCheckboxChange,
  totalRecords,
  selectedRows,
  setCsvExportData,
  currentPage,
  onPageChange,
  onRowsPerPageChange
}) {

  const [updateEmailVerification, { isLoading, isError, isSuccess }] =
    useUpdateEmailVerificationMutation()

  const handleReVerification = async (email, id) => {
    const formData = { email, id }
    try {
      const result = await updateEmailVerification(formData)
      refetchData()
    } catch (error) {
      console.error('Mutation Error:', error)
    }
  }
  // Modify the data to fit the table component
  const updatedData = data.map(item => {
    if (item.emailVerification && item.emailVerification.length > 0) {
      const mergedProperties = item.emailVerification[0]
      const updatedItem = { ...item, ...mergedProperties }
      // To modifY the Date time format
      if (mergedProperties.lastVerified) {
        const lastVerifiedDate = new Date(mergedProperties.lastVerified)
        const localLastVerifiedDateString = lastVerifiedDate.toLocaleString()
        updatedItem.lastVerified = localLastVerifiedDateString
      }
      updatedItem.blackListed = updatedItem.blackListed ? 'true' : 'false' 
      updatedItem.isRedFlagged = updatedItem.isRedFlagged ? 'true' : 'false' 
      updatedItem.isVerified = updatedItem.isVerified ? 'true' : 'false' 
      // Remove the emailVerification property
      delete updatedItem.emailVerification

      return updatedItem
    } else {
      return item
    }
  })

  // console.log(updatedData)

  const mapData = row => {
    const mappedRow = {}
    for (const [label, propertyName] of Object.entries(Header)) {
      mappedRow[label] = row[propertyName]
    }
    return mappedRow
  }

  useEffect(() => {
    exportCsv && setCsvExportData(updatedData)
  }, [])

  return (
    <>
      {updatedData?.length > 0 ? (
        <TableContainer sx={tableContainer}>
          <Table sx={{ marginBottom: '1rem' }}>
            <TableHead sx={tableHeader}>
              <TableRow>
                {duplicates && <TableCell></TableCell>}
                {label.map(item => (
                  <TableCell
                    key={item}
                    sx={headerText}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {updatedData &&
                updatedData.map((row, i) => (
                  <TableRow key={row + i}>
                    {duplicates && (
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedRows.includes(row.csvDataId)}
                              onChange={e =>
                                handleCheckboxChange(row.csvDataId, e)
                              }
                            />
                          }
                        />
                      </TableCell>
                    )}
                    {Object.entries(mapData(row)).map(
                      ([label, cell], index) => (
                        <TableCell key={index} align='left'>
                          {label === 'First Name' &&
                          row.repeat &&
                          !duplicates ? (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '-15px'
                              }}
                            >
                              <DuplicateDataModal
                                id={row.csvDataId}
                                refetchData={refetchData}
                              />

                              <Typography sx={statusText}>
                                <div
                                  style={{
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis'
                                  }}
                                >
                                  {cell}
                                </div>
                              </Typography>
                            </Box>
                          ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography sx={statusText}>
                                <div
                                  style={{
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis'
                                  }}
                                >
                                  {cell || '--'}
                                </div>
                              </Typography>
                            </Box>
                          )}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                sx={{marginLeft:0}}
                  rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                  colSpan={10}
                  count={totalRecords || data?.length}
                  rowsPerPage={rowsPerPage}
                  page={currentPage || 0}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  slotProps={{
                    select: {
                      inputProps: {
                        'aria-label': 'rows per page'
                      },
                      native: true
                    }
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <Box>
          <Typography sx={noRecord}>No Records Found</Typography>
        </Box>
      )}
    </>
  )
}
