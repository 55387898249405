import {
  Alert,
  Box,
  Button,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Filter from "./Filter";
import CloseIcon from "@mui/icons-material/Close";
import {
  filterHeader,
  saveFilterHeader,
  filterCard,
  CardHeader,
  container,
  saveFilterLabel,
} from "./styles";
import SecondaryButton from "../UIKit/Buttons/Secondary";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useFormik } from "formik";
import { SaveFilterValidation } from "../../utils/validation";
import {
  useDeleteFilterMutation,
  useGetFiltersWithConditionsQuery,
  useSaveFilterMutation,
  useUpdateFilterMutation,
} from "../../RTKQuery/CsvDataService/csvDataApi";
import Loader from "../UIKit/Loader";
import DeleteModal from "../DeleteModal/DeleteModal";

export default function ConditionalFilters({
  filtersArray,
  setFiltersArray,
  setCloseFilterMenu,
  filterCount,
  setFilterCount,
  conditionCount,
  setConditionCount,
  subConditionCount,
  setSubConditionCount,
  onReset,
  onSubmit,
  defaultFilter,
  campaignUsers = false,
}) {
  const [saveFilterModal, setSaveFilterModal] = useState(false);
  const [filter, setFilter] = React.useState("");
  const [notification, setNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [saveFilterSelection, setSaveFilterSelection] = useState(false);
  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [error, setError] = useState(null);
  const handleAddNewFilter = () => {
    setFilterCount((prevCount) => prevCount + 1);
    const newFilterId = `f-${filterCount + 1}`;
    setFiltersArray((prevFilters) => [
      ...prevFilters,
      {
        id: newFilterId,
        conditions: [
          {
            id: `${newFilterId}0`,
            filter: newFilterId,
            subConditions: [],
            first: true,
          },
        ],
      },
    ]);
  };

  // Function to add a new condition to a filter
  const handleAddNewCondition = (filterId) => {
    setConditionCount((prevCount) => prevCount + 1);
    const newConditionId = `${filterId}${conditionCount + 1}`;
    setFiltersArray((prevFilters) => {
      return prevFilters.map((filter) => {
        if (filter.id === filterId) {
          return {
            ...filter,
            conditions: [
              ...(filter.conditions || []),
              { id: newConditionId, filter: filterId, subConditions: [] },
            ],
          };
        }
        return filter;
      });
    });
  };

  // Function to add a new sub-condition to a condition
  const handleAddNewSubCondition = (conditionId) => {
    const uniqueId = `${conditionId}${subConditionCount}`;
    setSubConditionCount((prevCount) => prevCount + 1);
    setFiltersArray((prevFilters) => {
      return prevFilters.map((filter) => {
        if (filter.conditions) {
          return {
            ...filter,
            conditions: filter.conditions.map((condition) => {
              if (condition.id === conditionId) {
                return {
                  ...condition,
                  subConditions: [
                    ...(condition.subConditions || []),
                    { id: uniqueId },
                  ],
                };
              }
              return condition;
            }),
          };
        }
        return filter;
      });
    });
  };

  const handleRemoveFilter = (filterIdToRemove) => {
    setFiltersArray((prevFilters) =>
      prevFilters.filter((filter) => filter.id !== filterIdToRemove)
    );
  };

  const removeCondition = (conditionId) => {
    setFiltersArray((prevFilters) => {
      return prevFilters.map((filter) => {
        if (filter.conditions) {
          return {
            ...filter,
            conditions: filter.conditions.filter(
              (condition) => condition.id !== conditionId
            ),
          };
        }
        return filter;
      });
    });
  };

  const removeSubCondition = (conditionId, subConditionId) => {
    setFiltersArray((prevFilters) => {
      return prevFilters.map((filter) => {
        if (filter.conditions) {
          return {
            ...filter,
            conditions: filter.conditions.map((condition) => {
              if (condition.id === conditionId) {
                return {
                  ...condition,
                  subConditions: condition.subConditions.filter(
                    (subCondition) => subCondition.id !== subConditionId
                  ),
                };
              }
              return condition;
            }),
          };
        }
        return filter;
      });
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #f4f4f4",
    boxShadow: 24,
    p: 3,
    borderRadius: "8px",
    background: "#f2f2f2",
  };

  const [saveFilterMutation, { isLoading: saveFilterLoading }] =
    useSaveFilterMutation();

  const { data: saveFilters, loading: saveFiltersLoading } =
    useGetFiltersWithConditionsQuery();

  const [deleteFilter] = useDeleteFilterMutation();
  const [updateFilter] = useUpdateFilterMutation();

  const formik = useFormik({
    initialValues: {
      saveFilter: "",
    },
    onSubmit: (values) => {
      const filterData = {
        title: values.saveFilter,
        filterConditions: filtersArray,
      };
      saveFilterMutation({ body: filterData }).then((response) => {
        if ("data" in response) {
          if (!response.data.message.includes("successfully")) {
            setError(response.data.message);
            setNotificationMessage(response.data.message);
          } else {
            setSaveFilterModal(false);
            setNotification(true);
            setNotificationMessage(response.data.message);
          }
        }
      });
    },
    validationSchema: SaveFilterValidation,
  });

  const handleChangeSaveFilter = (event) => {
    setDeleteStatus(false);
    const selectedId = event.target.value;
    setFilter(selectedId);
    const selectedFilterObj = saveFilters.find(
      (filter) => filter.id === selectedId
    );
    setFiltersArray(selectedFilterObj.filterConditions);
    setSaveFilterSelection(true);
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification(false);
  };

  useEffect(() => {
    setError(null);
  }, [saveFilters]);

  const handleDelete = async () => {
    setDeleteStatus(true);
    setDeleteModalStatus(true);
    setFiltersArray(defaultFilter);
    setSaveFilterSelection(false);
    try {
      await deleteFilter(filter);
      setDeleteModalStatus(false);
      setNotification(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateFilter = async () => {
    updateFilter({ id: filter, body: { filterConditions: filtersArray } }).then(
      (response) => {
        setNotification(true);
        if ("data" in response) {
          setNotificationMessage(response.data.message);
        } else {
          setNotificationMessage(response.data.message);
        }
      }
    );
  };

  return (
    <>
      {saveFilterLoading || saveFiltersLoading ? (
        <Loader />
      ) : (
        <>
          <Box>
            {/* <div style={filterHeader}>
              <h2>Filters:</h2>
              <IconButton onClick={() => setCloseFilterMenu(true)}>
                <CloseIcon />
              </IconButton>
            </div> */}

            <Box sx={saveFilterLabel}>
              <h4>Saved Filters:</h4>
              <FormControl size="small">
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ fontSize: "13px" }}
                >
                  Select Saved Filters
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={filter}
                  onChange={handleChangeSaveFilter}
                  id="demo-simple-select"
                  label="Select Saved Filters"
                  sx={{
                    backgroundColor: "white",
                    width: "13rem",
                    fontSize: "13px",
                    margin: 0,
                  }}
                  renderValue={(selected) => {
                    const selectedFilterObj = saveFilters.find(
                      (filter) => filter.id === selected
                    );

                    return selectedFilterObj ? selectedFilterObj.title : "";
                  }}
                >
                  {saveFilters?.length ? (
                    saveFilters?.map((savedFilters, index) => (
                      <MenuItem value={savedFilters.id} key={index}>
                        {savedFilters.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled>
                      No saved filters yet
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>

            {filtersArray.length > 0 &&
              filtersArray.map((item) => (
                <div style={filterCard} key={`filter-${item.id}`}>
                  <div style={CardHeader}>
                    <h4>Filter:</h4>
                    {!item.first && (
                      <IconButton onClick={() => handleRemoveFilter(item.id)}>
                        <HighlightOffIcon />
                      </IconButton>
                    )}
                  </div>
                  <div>
                    <Filter
                      filterId={item.id}
                      data={item}
                      filterKey={`filter-${item.id}`}
                      setFiltersArray={setFiltersArray}
                      filtersArray={filtersArray}
                      conditionsArray={item.conditions}
                      handleAddNewSubCondition={handleAddNewSubCondition}
                      conditionId={item.id}
                      removeCondition={removeCondition}
                      removeSubCondition={removeSubCondition}
                      campaignUsers={campaignUsers}
                    />
                  </div>
                  <SecondaryButton
                    text={"Add Condition"}
                    variant={"text"}
                    color={"#2AB38E"}
                    icon={<AddCircleOutlineIcon />}
                    onClick={() => handleAddNewCondition(item.id)}
                  />
                </div>
              ))}
          </Box>
          <div
            style={{ margin: "0 1rem", borderBottom: "1px solid lightgray" }}
          >
            <div style={{ marginLeft: "82%" }}>
              <SecondaryButton
                text={"Add Section"}
                variant={"text"}
                color={"#2AB38E"}
                icon={<AddCircleOutlineIcon />}
                onClick={handleAddNewFilter}
              />
            </div>
          </div>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              justifyContent: "flex-end",
              padding: "1rem",
            }}
          >
            {saveFilterSelection && (
              <Button
                color="error"
                variant="contained"
                onClick={() => setDeleteModalStatus(true)}
              >
                Delete Saved Filter
              </Button>
            )}
            <Button
              color="primary"
              variant="contained"
              onClick={
                saveFilterSelection
                  ? handleUpdateFilter
                  : () => setSaveFilterModal(true)
              }
            >
              {saveFilterSelection ? "Update Saved Filter" : "Save Filter"}
            </Button>
            <Button color="primary" variant="contained" onClick={onSubmit}>
              Apply
            </Button>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              onClick={onReset}
              style={{ color: "white" }}
            >
              Reset
            </Button>
          </Box>
          <Modal
            open={saveFilterModal}
            onClose={() => setSaveFilterModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Fade in={saveFilterModal}>
              <Box sx={style}>
                <div style={container}>
                  <IconButton
                    style={saveFilterHeader}
                    onClick={() => setSaveFilterModal(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {saveFilterSelection ? "Update" : "Enter"} filter name
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2.5 }}>
                    <form noValidate onSubmit={formik.handleSubmit}>
                      <TextField
                        size="small"
                        fullWidth
                        id="saveFilter"
                        name="saveFilter"
                        label={`${
                          saveFilterSelection ? "Update" : "Save"
                        } Filter Name`}
                        variant="outlined"
                        value={formik.values.saveFilter}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.saveFilter &&
                          Boolean(formik.errors.saveFilter)
                        }
                      />
                      {formik.touched.saveFilter && (
                        <div style={{ fontSize: "14px", color: "red" }}>
                          {formik.errors.saveFilter}
                        </div>
                      )}
                      {error && (
                        <div style={{ fontSize: "14px", color: "red" }}>
                          {error}
                        </div>
                      )}
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        style={{ marginTop: "18px", float: "right" }}
                      >
                        {saveFilterSelection ? "Update" : "Save"}
                      </Button>
                    </form>
                  </Typography>
                </div>
              </Box>
            </Fade>
          </Modal>
          <Snackbar
            open={notification}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleCloseNotification}
          >
            <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
              {deleteStatus
                ? "Filter deleted successfully"
                : notificationMessage}
            </Alert>
          </Snackbar>
          <DeleteModal
            open={deleteModalStatus}
            setOpen={setDeleteModalStatus}
            name={saveFilters && saveFilters.name}
            type={"Tag"}
            handleDelete={handleDelete}
            message={"Are you sure you want to delete this filter?"}
          />
        </>
      )}
    </>
  );
}
