import * as React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { tableCell } from './styles'
import Checkbox from '@mui/material/Checkbox'
import { useState } from 'react'
import { customDateFormat } from '../../../utils/helperFunctions'
import { Tooltip } from '@mui/material'
import DuplicateDataModal from '../../Modals/DuplicateModal'

function Row ({
  row,
  header,
  handleCheckboxChange,
  selectedItems,
  showSelection,
  stats
}) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      {row && (
        <React.Fragment>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            {showSelection ? (
              <TableCell>
                <Checkbox
                  onChange={event => handleCheckboxChange(event, row)}
                  checked={selectedItems.some(
                    selectedItem => selectedItem.id === row.id
                  )}
                />
              </TableCell>
            ) : row?.campaigns &&
              row?.campaigns[0]?.campaignUserStats?.length > 0 &&
              !showSelection ? (
              <TableCell>
                <IconButton
                  aria-label='expand row'
                  size='small'
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
            ) : (
              <></>
            )}

            {row?.repeat && row.repeat.length > 0 && (
              <TableCell>
                <DuplicateDataModal id={row.id} />
              </TableCell>
            )}

            {/* <TableCell sx={tableCell} align='left'>
              {row?.id}
            </TableCell> */}
            <TableCell sx={tableCell} align='left'>
              {row?.firstName}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.lastName}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.email}
            </TableCell>
            {/* <TableCell sx={tableCell} align='left'>
              {row?.emailVerification[0]?.verified
                ? 'Verified'
                : 'Not Verified' || 'Not Verified'}
            </TableCell> */}
            <TableCell sx={tableCell} align='left'>
              {row?.blackListed ? 'true' : 'false'}
            </TableCell>
            {stats ? (
              <>
                <TableCell sx={tableCell} align='left'>
                  {row?.isRedFlagged ? 'true' : 'false'}
                </TableCell>
                <TableCell sx={tableCell} align='left'>
                  {row?.isVerified ? 'true' : 'false'}
                </TableCell>
                <TableCell sx={tableCell} align='left'>
                  {row?.campaigns?.length}
                </TableCell>
                <TableCell sx={tableCell} align='left'>
                  {row?.totalSent}
                </TableCell>
                <TableCell sx={tableCell} align='left'>
                  {row?.totalOpens}
                </TableCell>
                <TableCell sx={tableCell} align='left'>
                  {row?.totalClicks}
                </TableCell>
                <TableCell sx={tableCell} align='left'>
                  {row?.openRate} %
                </TableCell>
              </>
            ) : (
              <>
                {/* <TableCell sx={tableCell} align="left">
             {row?.title}
             </TableCell> 
            <TableCell sx={tableCell} align='left'>
              {row?.company}
            </TableCell>
             <TableCell sx={tableCell} align="left">
              {row?.companyAddress}
            </TableCell>
            <TableCell sx={tableCell} align="left">
              {row?.department}
            </TableCell>
            <TableCell sx={tableCell} align="left">
              {row?.seniority}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.campaigns?.length}
            </TableCell> */}
                <TableCell sx={tableCell} align='left'>
                  {row?.isRedFlagged ? 'true' : 'false'}
                </TableCell>
                <TableCell sx={tableCell} align='left'>
                  {row?.isVerified ? 'true' : 'false'}
                </TableCell>
                <TableCell sx={tableCell} align='left'>
                  {row?.campaigns?.length}
                </TableCell>
              </>
            )}
            {row.campaigns &&
              row?.campaigns[0]?.campaignUserStats?.length > 0 &&
              showSelection && (
                <TableCell>
                  <IconButton
                    aria-label='expand row'
                    size='small'
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
              )}
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                backgroundColor: '#FAFAFA'
              }}
              colSpan={14}
            >
              <Collapse in={open} timeout='auto' unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table size='small' aria-label='purchases'>
                    <TableHead>
                      <TableRow>
                        {header &&
                          header?.map((item, index) => (
                            <TableCell key={index}>{item}</TableCell>
                          ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {/* {console.log(row)} */}
                      {row.campaigns &&
                        row?.campaigns[0]?.campaignUserStats?.map(data => (
                          <TableRow
                            key={data.id + '-row' + row.campaigns[0]?.id + '-'}
                          >
                            <TableCell component='th' scope='row'>
                              {row.campaigns[0]?.campaignTitle}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {data.event}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {customDateFormat(
                                data.eventTime,
                                'YYYY-MM-DD HH:mm:ss'
                              )}
                            </TableCell>
                            {/* <TableCell component='th' scope='row'>
                                  {customDateFormat(
                                    data.sentAt,
                                    'YYYY-MM-DD HH:mm:ss'
                                  )}
                                </TableCell> */}
                            {/* <TableCell component='th' scope='row'>
                                  {customDateFormat(
                                    data.repliedAt,
                                    'YYYY-MM-DD HH:mm:ss'
                                  )}
                                </TableCell> */}
                            {/* <TableCell component='th' scope='row'>
                                  {customDateFormat(
                                    data.openedAt,
                                    'YYYY-MM-DD HH:mm:ss'
                                  )}
                                </TableCell> */}
                            {/* <TableCell component='th' scope='row'>
                                  {data.clickedAt}
                                </TableCell> */}
                            <TableCell component='th' scope='row'>
                              <a target='_blank' href={data.clickedLink}>
                                {data.clickedLink}
                              </a>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      )}
    </>
  )
}

function CampaignsRow ({
  row,
  header,
  handleCheckboxChange,
  selectedItems,
  showSelection,
  actions
}) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      {row && (
        <React.Fragment>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell></TableCell>
            {/* <TableCell sx={tableCell} align='left'>
              {row?.id}
            </TableCell> */}
            <TableCell sx={tableCell} align='left'>
              {row?.campaignTitle}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.campaignSubject}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.owner}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.industry}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.approved}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.startDate}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.endDate}
            </TableCell>

            <TableCell
              style={{ display: 'flex', gap: '.3rem', border: 'none' }}
            >
              <>{React.cloneElement(actions, { id: row.id })}</>
              {(row.totalSent || row.totalProcessed > 0) && (
                <Tooltip title='View stats'>
                  <IconButton
                    aria-label='expand row'
                    size='small'
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                backgroundColor: '#FAFAFA'
              }}
              colSpan={16}
            >
              <Collapse in={open} timeout='auto' unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table size='small' aria-label='purchases'>
                    <TableHead>
                      <TableRow>
                        {header &&
                          header?.map((item, index) => (
                            <TableCell key={index}>{item}</TableCell>
                          ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {/* {console.log(row)} */}
                      {(row.totalSent || row.totalProcessed > 0) && (
                        <>
                          <TableRow key={row.id + 'camaign'}>
                            <TableCell component='th' scope='row'>
                              {row.totalProcessed}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {row.totalSent}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {row.totalOpen}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {row.replies}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {row.clicks}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {row.openRate}%
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {row.clickRate}%
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {row.repliedRate}%
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      )}
    </>
  )
}

export default function SelectionCollapsibleTable ({
  header = [],
  subHeader = [],
  tableSize,
  rows,
  showSelection,
  handleSelectionChange,
  onRowsPerPageChange,
  rowsPerPage,
  currentPage,
  totalRecords,
  onPageChange,
  forCampaigns = false,
  actions,
  stats = false
}) {
  // console.log(rows)
  // handle row selection
  const [selectedItems, setSelectedItems] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  const handleCheckboxChange = (event, item) => {
    const selectedIndex = selectedItems.findIndex(
      selectedItem => selectedItem.id === item.id
    )
    let newSelected = [...selectedItems]

    if (selectedIndex === -1) {
      newSelected = [...selectedItems, item]
    } else {
      newSelected.splice(selectedIndex, 1)
    }

    setSelectedItems(newSelected)
    handleSelectionChange(newSelected.map(item => item.id))
    // setSelectAll(newSelected.length > 0 && newSelected.length === rows.length)
  }

  // Function to handle select all rows in the current page
  const handleSelectAll = event => {
    if (event.target.checked) {
      const newSelected = [
        ...selectedItems,
        ...rows.filter(row => !selectedItems.some(item => item.id === row.id))
      ]
      setSelectedItems(newSelected)
      handleSelectionChange(newSelected.map(item => item.id))
      // setSelectAll(true)
    } else {
      const newSelected = selectedItems.filter(
        item => !rows.some(row => row.id === item.id)
      )
      setSelectedItems(newSelected)
      handleSelectionChange(newSelected.map(item => item.id))
      // setSelectAll(false)
    }
  }
  // Function to determine if all rows in the current page are selected
  const isPageSelected = () => {
    return (
      rows.length > 0 &&
      rows.every(row => selectedItems.some(item => item.id === row.id))
    )
  }

  // console.log(rows)
  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: '0 0 0 0', marginTop: '1.5rem' }}
    >
      <Table aria-label='collapsible table' size={tableSize}>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'lightgray' }}>
            <TableCell>
              {showSelection && (
                <Checkbox
                  indeterminate={
                    selectedItems.length > 0 &&
                    selectedItems.length < rows.length
                  }
                  checked={isPageSelected()}
                  onChange={handleSelectAll}
                />
              )}
            </TableCell>
            {/* <TableCell></TableCell> */}
            {rows[1]?.repeat &&
              rows[0].repeat.length > 0 &&
              // <TableCell>{/* <DuplicateDataModal/> */}</TableCell>
              console.log(rows)}
            {header &&
              header?.map((item, idx) => (
                <TableCell key={item + '-' + idx} sx={tableCell} align='left'>
                  {item}
                </TableCell>
              ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => {
            if (forCampaigns) {
              return (
                <CampaignsRow
                  key={idx + '-campaigns-' + idx}
                  row={row}
                  header={subHeader}
                  selectedItems={selectedItems}
                  showSelection={showSelection}
                  actions={actions}
                />
              )
            } else {
              return (
                <Row
                  key={idx + '-row-' + idx}
                  row={row}
                  header={subHeader}
                  handleCheckboxChange={handleCheckboxChange}
                  selectedItems={selectedItems}
                  showSelection={showSelection}
                  stats={stats}
                />
              )
            }
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
              colSpan={14}
              count={totalRecords || rows?.length}
              rowsPerPage={rowsPerPage}
              page={currentPage || 0}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
