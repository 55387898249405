import React, { useEffect, useMemo, useState } from 'react'
import { useGetAllUsersDataQuery } from '../../RTKQuery/CsvDataService/csvDataApi'
import Loader from '../Loader/Loader'
import PageComponentHeader from '../PageComponentHeader'
import FilterDrawer from '../UIKit/Drawer'
import ConditionalFilters from '../ConditionalFilters'
import { useLazyExportUsersQuery } from '../../RTKQuery/CsvDataService/csvDataApi'
import ExportButton from '../UIKit/Buttons/Export'
import DataTable from '../UIKit/CollapsibleTable'
import { userListColumns } from '../../constants/tableColumns'
import MuiMultipleSelect from '../UIKit/MultiSelect'
import { UserDataColumns } from '../../constants/componentConstants'

export default function UsersList ({ filters, setFilters }) {
  const myColumns = localStorage.getItem('columns')?.split(',')
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25
  })
  const [sorting, setSorting] = useState([])
  const [usersData, setUsersData] = useState([])
  // Filters
  const [closeFilterMenu, setCloseFilterMenu] = useState(true)
  const [filterCount, setFilterCount] = useState(1)
  const [conditionCount, setConditionCount] = useState(0)
  const [subConditionCount, setSubConditionCount] = useState(0)
  const [columns, setColumns] = useState(myColumns || UserDataColumns)
  const [savedColumns, setSavedColumns] = useState(myColumns || UserDataColumns)
  const [loader, setLoader] = useState(true)
  const [triggerExport, { isLoading }] = useLazyExportUsersQuery()

  const defaultFilter = [
    {
      id: 'f-1',
      first: true,
      conditions: [
        {
          id: 'f-10',
          first: true,
          subConditions: [],
          filter: 'f-1'
        }
      ]
    }
  ]
  const [filterData, setFilterData] = useState(defaultFilter)

  // Applying Filters
  const {
    data,
    loading: dataLoading,
    refetch
  } = useGetAllUsersDataQuery(
    {
      pagination,
      columns: savedColumns,
      sorting,
      filters: JSON.stringify(filters)
    }
    // { skip: !shouldFetchData }
  )

  const [loading, setLoading] = useState(null)

  const filteredColumns = userListColumns.filter(column =>
    columns.includes(column.accessorKey)
  )

  const handleApplyFilters = () => {
    // setCurrentPage(0)
    setPagination({ ...pagination, pageIndex: 0 })
    setFilters(filterData)
    setCloseFilterMenu(true)
  }

  const handleResetFilters = () => {
    // setCurrentPage(0)
    setPagination({ ...pagination, pageIndex: 0 })
    setFilterData([
      {
        id: 'f-1',
        first: true,
        conditions: [
          {
            id: 'f-10',
            first: true,
            subConditions: [],
            filter: 'f-1'
          }
        ]
      }
    ])
    setFilters([])
    setCloseFilterMenu(true)
  }
  const handleExport = async () => {
    try {
      await triggerExport(filters).unwrap()
    } catch (error) {
      console.error('Error exporting users:', error)
    }
  }

  const onColmnSelect = () => {
    setSavedColumns(columns)
    localStorage.setItem('columns', columns)
    setCloseFilterMenu(true)
  }

  useEffect(() => {
    // refetch()
    if (dataLoading) {
      console.log('loading')
      setLoading(dataLoading)
    }
    if (data) {
      setLoader(false)
      setUsersData(data.users)
    }
  }, [data, usersData, dataLoading])

  const tableColumns = useMemo(() => filteredColumns, [filteredColumns])

  return (
    <div>
      <PageComponentHeader allowBack={false} pageTitle={'Users Management'}>
        <ExportButton onClick={handleExport} isLoading={isLoading} />
        <FilterDrawer
          close={closeFilterMenu}
          setClose={setCloseFilterMenu}
          filters={filters}
          setCloseFilterMenu={setCloseFilterMenu}
        >
          <>
            {/* <MuiMultipleSelect
              width={'100%'}
              fontSize={'12px'}
              onClick={onColmnSelect}
              columns={columns}
              setColumns={setColumns}
              label={'Column names'}
            /> */}
            <ConditionalFilters
              onReset={handleResetFilters}
              onSubmit={handleApplyFilters}
              filtersArray={filterData}
              setFiltersArray={setFilterData}
              setCloseFilterMenu={setCloseFilterMenu}
              filterCount={filterCount}
              setFilterCount={setFilterCount}
              conditionCount={conditionCount}
              setConditionCount={setConditionCount}
              subConditionCount={subConditionCount}
              setSubConditionCount={setSubConditionCount}
              defaultFilter={defaultFilter}
            />
          </>
        </FilterDrawer>
      </PageComponentHeader>
      {data?.users.length === 0 ? (
        <h3 className='noData'>No Record Found</h3>
      ) : !loader && data?.users.length > 0 ? (
        <div style={{ marginTop: '1rem' }}>
          <DataTable
            columns={tableColumns}
            data={usersData}
            totalData={data.total}
            sorting={sorting}
            setSorting={setSorting}
            pagination={pagination}
            setLoader={setLoader}
            setPagination={setPagination}
          />
        </div>
      ) : (
        <div className='noData'>
          <Loader />
        </div>
      )}
    </div>
  )
}
