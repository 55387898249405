import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithReauth from '../../utils/basequery'

export const csvDataApi = createApi({
  reducerPath: 'csvApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['filters', 'users'],
  endpoints: builder => ({
    createCsvData: builder.mutation({
      query: body => ({
        url: 'csv-data',
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'csv' }, { type: 'users' }]
    }),
    createCsvDataInCampaign: builder.mutation({
      query: body => ({
        url: 'csv-data/campaign',
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'csv' }, { type: 'users' }]
    }),
    createApolloCsvData: builder.mutation({
      query: body => ({
        url: 'apolo-messages',
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'csv' }, { type: 'users' }]
    }),
    getCsvDuplicateData: builder.query({
      query: id => ({
        url: `csv-data/${id}`,
        method: 'GET'
      }),
      invalidatesTags: [{ type: 'csv' }]
    }),
    getAllUsersData: builder.query({
      query: args => {
        const { pagination, sorting, filters, columns } = args
        return {
          url: 'csv-data/users',
          params: {
            page: pagination.pageIndex,
            rows: pagination.pageSize,
            sorting: JSON.stringify(sorting),
            filters: filters,
            columns
          }
        }
      },
      providesTags: ['users']
    }),
    exportUsers: builder.query({
      query: filters => ({
        url: 'csv-data/export',
        params: { filters: JSON.stringify(filters) },
        responseHandler: async response => {
          const blob = await response.blob()
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'users.csv')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          // Return something serializable or undefined since we handle download externally
          return undefined
        }
      })
    }),
    exportCampaignUsers: builder.query({
      query: args => {
        const { id, filters } = args
        console.log(filters)
        return {
          url: 'csv-data/export/' + id,
          params: { filters: JSON.stringify(filters) },
          responseHandler: async response => {
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'users.csv')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            // Return something serializable or undefined since we handle download externally
            return undefined
          }
        }
      }
    }),
    getCompaniesAndDepartments: builder.query({
      query: () => ({
        url: 'csv-data'
      })
    }),

    DeleteDuplicateData: builder.mutation({
      query: ids => ({
        url: 'csv-data',
        method: 'DELETE',
        body: ids
      }),
      invalidatesTags: [{ type: 'users' }]
    }),
    updateDuplicateData: builder.mutation({
      query: body => ({
        url: `csv-data/duplicate`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['users']
    }),
    getCampaignUsers: builder.query({
      query: args => {
        const { id, page, rows, filters } = args
        return {
          url: `csv-data/users/${id}`,
          params: { page, rows, filters }
        }
      }
    }),
    saveFilter: builder.mutation({
      query: body => ({
        url: 'filters',
        method: 'POST',
        body
      }),
      invalidatesTags: ['filters']
    }),
    getFilters: builder.query({
      query: () => ({
        url: 'filters',
        method: 'GET'
      }),
      providesTags: ['filters']
    }),
    getFiltersWithConditions: builder.query({
      query: () => ({
        url: 'filters/conditions',
        method: 'GET'
      }),
      providesTags: ['filters']
    }),
    deleteFilter: builder.mutation({
      query: id => ({
        url: 'filters/' + id,
        method: 'DELETE',
        body: id
      }),
      invalidatesTags: ['filters']
    }),
    updateFilter: builder.mutation({
      query: ({ id, body }) => ({
        url: `filters/${id}`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['filters']
    })
  })
})

export const {
  useCreateCsvDataMutation,
  useCreateCsvDataInCampaignMutation,
  useCreateApolloCsvDataMutation,
  useGetCsvDuplicateDataQuery,
  useGetAllUsersDataQuery,
  useLazyExportUsersQuery,
  useLazyExportCampaignUsersQuery,
  useGetCompaniesAndDepartmentsQuery,
  useDeleteDuplicateDataMutation,
  useUpdateDuplicateDataMutation,
  useGetCampaignUsersQuery,
  useSaveFilterMutation,
  useGetFiltersQuery,
  useGetFiltersWithConditionsQuery,
  useDeleteFilterMutation,
  useUpdateFilterMutation
} = csvDataApi
